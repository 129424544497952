export default class CustomFilter {
    constructor(customFilter) {
        this.customFilter = customFilter
        this.dropdowns = Array.from(customFilter.querySelectorAll('.filter-dropdown'))
        this.filterButtonDesktop = this.customFilter.querySelector('.filter-apply.desktop')
        this.filterButtonMobile = this.customFilter.querySelector('.filter-apply.mobile')
        this.openPopupButton = this.customFilter.querySelector('.open-filter-popup')
        this.filterPopup = this.customFilter.querySelector('.filter-popup')
        this.accordions = Array.from(this.filterPopup.querySelectorAll('.accordion'))
    }

    start() {
        this.setDefaultSelections() // Initialize filters based on query params
        this.addDropdownListeners()
        this.addFilterButtonDesktopListener() // Add desktop filter functionality
        this.addPopupListeners() // Handle popup opening and closing
        this.addAccordionListeners() // Add listeners for accordion items
        this.addFilterButtonMobileListener() // Add mobile filter functionality
    }

    addDropdownListeners() {
        this.dropdowns.forEach(dropdown => {
            const displayInput = dropdown.querySelector('.dropdown-display')
            const menu = dropdown.querySelector('.dropdown-menu')
            const items = Array.from(menu.querySelectorAll('.filter-menu-item'))

            dropdown.addEventListener('click', () => {
                this.toggleDropdown(dropdown)
            })

            items.forEach(item => {
                item.addEventListener('click', e => {
                    e.stopPropagation() // Prevent dropdown from closing
                    item.classList.toggle('selected') // Toggle selection
                    const defaultValue = displayInput.getAttribute('value')
                    this.updateDisplay(displayInput, items, defaultValue)
                })
            })

            document.addEventListener('click', e => {
                if (!dropdown.contains(e.target)) {
                    this.closeDropdown(dropdown)
                }
            })
        })
    }

    addAccordionListeners() {
        this.accordions.forEach(accordion => {
            const header = accordion.querySelector('.accordion-header')
            const body = accordion.querySelector('.accordion-body')
            const displayInput = header.querySelector('.accordion-display')
            const items = Array.from(body.querySelectorAll('.filter-menu-item'))

            header.addEventListener('click', () => {
                // Close all other accordions
                this.accordions.forEach(otherAccordion => {
                    if (otherAccordion !== accordion) {
                        otherAccordion.classList.remove('active')
                        const otherBody = otherAccordion.querySelector('.accordion-body')
                        if (otherBody) {
                            otherBody.style.maxHeight = null
                        }
                    }
                })

                // Toggle the clicked accordion
                accordion.classList.toggle('active')
                if (body.style.maxHeight) {
                    body.style.maxHeight = null // Close accordion
                } else {
                    body.style.maxHeight = body.scrollHeight + 'px' // Open accordion
                }
            })

            items.forEach(item => {
                item.addEventListener('click', () => {
                    item.classList.toggle('selected') // Toggle selection
                    const defaultValue = displayInput.getAttribute('value')
                    this.updateDisplay(displayInput, items, defaultValue)
                })
            })
        })
    }

    toggleDropdown(dropdown) {
        dropdown.classList.toggle('active')
    }

    closeDropdown(dropdown) {
        dropdown.classList.remove('active')
    }

    updateDisplay(displayInput, items, defaultValue = '') {
        const selectedValues = items
            .filter(item => item.classList.contains('selected'))
            .map(item => {
                const label = item.querySelector('.filter-menu-item-label')
                return label ? label.textContent : item.textContent
            })

        // If no selected values, reset to the default value
        displayInput.value = selectedValues.length > 0 ? selectedValues.join(', ') : defaultValue
    }

    setDefaultSelections() {
        const urlParams = new URLSearchParams(window.location.search)

        // Handle desktop dropdowns
        this.dropdowns.forEach(dropdown => {
            const menu = dropdown.querySelector('.dropdown-menu')
            const items = Array.from(menu.querySelectorAll('.filter-menu-item'))
            const taxonomy = dropdown.id.replace('dropdown-', '') // Get the taxonomy from the dropdown ID

            if (urlParams.has(taxonomy)) {
                const selectedValues = urlParams.get(taxonomy).split(',')

                items.forEach(item => {
                    const value = item.getAttribute('data-value')
                    if (selectedValues.includes(value)) {
                        item.classList.add('selected')
                    }
                })

                // Update the display input to show selected values
                const displayInput = dropdown.querySelector('.dropdown-display')
                this.updateDisplay(displayInput, items)
            }
        })

        // Handle mobile accordions
        this.accordions.forEach(accordion => {
            const body = accordion.querySelector('.accordion-body')
            const items = Array.from(body.querySelectorAll('.filter-menu-item'))
            const displayInput = accordion.querySelector('.accordion-display')
            const taxonomy = accordion.dataset.taxonomy

            if (urlParams.has(taxonomy)) {
                const selectedValues = urlParams.get(taxonomy).split(',')

                items.forEach(item => {
                    const value = item.getAttribute('data-value')
                    if (selectedValues.includes(value)) {
                        item.classList.add('selected')
                    }
                })

                // Update the display input to show selected values
                this.updateDisplay(displayInput, items)
            }
        })
    }

    addFilterButtonDesktopListener() {
        this.filterButtonDesktop.addEventListener('click', e => {
            e.preventDefault() // Prevent default button behavior
            this.applyFiltersDesktop()
        })
    }

    addFilterButtonMobileListener() {
        this.filterButtonMobile.addEventListener('click', e => {
            e.preventDefault() // Prevent default button behavior
            this.applyFiltersMobile()
        })
    }

    applyFiltersDesktop() {
        const url = new URL(window.location.href)

        // Collect selected values from desktop dropdown filters
        this.dropdowns.forEach(dropdown => {
            const menu = dropdown.querySelector('.dropdown-menu')
            const items = Array.from(menu.querySelectorAll('.filter-menu-item.selected'))
            const taxonomy = dropdown.id.replace('dropdown-', '') // Get the taxonomy from the dropdown ID
            const selectedValues = items.map(item => item.getAttribute('data-value'))

            if (selectedValues.length > 0) {
                url.searchParams.set(taxonomy, selectedValues.join(','))
            } else {
                url.searchParams.delete(taxonomy) // Remove taxonomy if no filters are selected
            }
        })

        // Redirect with the updated query parameters
        window.location.href = url.toString()
    }

    applyFiltersMobile() {
        const url = new URL(window.location.href)

        // Collect selected values from mobile accordion filters
        this.accordions.forEach(accordion => {
            const taxonomy = accordion.dataset.taxonomy
            const items = Array.from(accordion.querySelectorAll('.accordion-body .filter-menu-item.selected'))
            const selectedValues = items.map(item => item.getAttribute('data-value'))

            if (selectedValues.length > 0) {
                url.searchParams.set(taxonomy, selectedValues.join(','))
            } else {
                url.searchParams.delete(taxonomy) // Remove taxonomy if no filters are selected
            }
        })

        // Redirect with the updated query parameters
        window.location.href = url.toString()
    }

    addPopupListeners() {
        this.openPopupButton.addEventListener('click', () => {
            this.filterPopup.classList.add('active') // Show the popup
            document.body.classList.add('overlay')
        })

        // Close the popup if the backdrop is clicked
        this.filterPopup.addEventListener('click', e => {
            if (e.target === this.filterPopup) {
                this.filterPopup.classList.remove('active') // Close the popup if the backdrop is clicked
                document.body.classList.remove('overlay')
            }
        })
    }
}
